<template>
  <div>
    <div v-if="this.$route.name === 'PriceListTable'">
      <PriceListTable />
    </div>
    <router-view :key="$route.fullPath"/>
  </div>
</template>
<script>
import PriceListTable from "@/views/companyApp/priceList/PriceListTable.vue";

export default {
  name: 'PriceListParent',
  components: {
    PriceListTable
  }
}
</script>
