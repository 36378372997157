<template>
  <div>
    <v-alert
      :value="alert"
      color="error"
      dark
      :dense="true"
      border="top"
      transition="scroll-y-transition"
    >
      <template v-slot:prepend>
        <v-icon small>mdi-alert</v-icon>
      </template>
      <template v-slot:append>
        <v-icon @click="$emit('update:alert', false)">mdi-close</v-icon>
      </template> 
      <span class="text-body-2 ml-2 ma-1">{{ dynamicMsg ? message : $t(message) }}</span>
    </v-alert>
  </div>
</template>
<script>

export default {
  name: 'Alert',
  props: ['message', 'alert', 'dynamicMsg'],
  data() {
    return {
      timeout: 5000,
    }
  },
  watch: {
    alert(val) {
      if(val) {
        let self = this;
        setTimeout(() => {
          self.$emit('update:alert', false)
        }, self.timeout)
      }
    }
  },
}
</script>