<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="mb-2">
        <span class="font-weight-medium text-body-1 primary--text text--darken-1">{{ $t('route.priceList') }}</span>
        <v-btn
          class="float-end"
          outlined
          small
          color="primary"
          @click="priceListFormDialog = true"
          :disabled="(accessRight.length < 2 && accessRight.includes('show'))"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          {{ $t('route.priceList') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card
          class="mx-auto"
          outlined
        >
          <ListSkeleton v-if="listSkeleton"/>
          <v-card-text class="text-h5 pa-0" v-else-if="priceListData.data">
            <v-data-table
              :dense="true"
              class="custom-table mb-0 ma-2 row-pointer"
              :headers="headers"
              :items="priceListData.data"
              :items-per-page="limit"
              :page.sync="page"
              style="word-break: break-word"
              :options.sync="pagination"
              item-class="py-4"
              :server-items-length="priceListData.meta.total"
              :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50]
              }"
              :search="search"
              @dblclick:row="redirectOnEdit"
              :sort-by.sync="sortByValue"
              :sort-desc.sync="sortDescValue"
            >
              <template
                v-slot:top
              >
                <div class="text-body-2 primary--text mb-2">
                  <v-row :dense="true">
                    <v-col cols="8">
                    </v-col>
                    <v-col cols="4" class="text-end d-flex">
                      <v-text-field
                        :full-width="false"
                        :dense="true"
                        hide-details="auto"
                        v-model="search"
                        append-icon="mdi-magnify"
                        :label="$t('common.search')"
                        single-line
                      />
                    </v-col>
                  </v-row>
                </div>
                <v-divider />
              </template>
              <template v-slot:[`item.actions`]="props">
                <v-icon @click="confirmationDialog = true, delete_item = props.item" class="error--text">mdi-delete-sweep-outline</v-icon>
              </template>
              <template v-slot:[`footer.prepend`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto" cols="12">
                    <span class="pl-3 pt-2">{{ $t('common.numberOfTotalRows') }} {{priceListData.meta.total}}</span>
                  </v-col>
                </v-row>
              </template> 
              <template v-slot:[`footer.page-text`]>
                <v-row class="d-flex">
                  <v-col class="mx-auto mr-16" cols="4">
                    <v-container class="w-100">
                      <v-pagination
                        :total-visible="5"
                        v-model="page"
                        @input="buildUrl"
                        :length="priceListData.meta.last_page"
                      ></v-pagination>
                    </v-container>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-text class="text-caption text-center" v-else> {{$t('common.noDataFound')}}</v-card-text>
        </v-card>
      </v-col>
      <confirmation-dialog :dialog.sync="confirmationDialog" :delete_item.sync="delete_item" @deleteItem="deleteItem"/>
      <PriceListForm :dialog.sync="priceListFormDialog" :editItemData="editItemData"/>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import ListSkeleton from '@/components/skeleton/ListSkeleton'
import ConfirmationDialog from '@/components/ConfirmationDialog';
import PriceListForm from './PriceListForm.vue'

export default {
  name: 'PriceListTable',
  components: {
    ListSkeleton,
    ConfirmationDialog,
    PriceListForm
  },
  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      limit: 10,
      sortByValue: this.$route.query.order_by || null,
      sortDescValue: this.$route.query.sortDec ? true : null,
      confirmationDialog: false,
      delete_item: null,
      listSkeleton: false,
      pagination: {
        page: Number(this.$route.query.page) || 1,
        itemsPerPage: 10,
        sortBy: [this.$route.query.order_by],
        sortDesc: this.$route.query.order_by ? this.$route.query.sortDec ? [true] : [false] : this.$route.query.sortDec ? [true] : [false]
      },
      search: this.$route.query.search || "",
      editItemData: {},
      priceListFormDialog: false,
    }
  },
  computed: {
    params() {
      return {
        sortBy: this.pagination.sortBy,
        sortDesc: this.pagination.sortDesc,
        page: this.pagination.page,
        itemsPerPage: this.pagination.itemsPerPage,
        query: this.search,
      };
    },
    ...mapGetters({
      accessRight: 'accessRight',
      locale: 'locale',
      priceListData: "priceList/priceListData",
    }),
    headers() {
      return [
        { text: this.$t('product.id'), value: "id", sortable: true },
        { text: this.$t('product.field.group'), value: "itemgroupid.description", sortable: false },
        { text: this.$t('product.priceListGroup'), value: "pricegroupid.description", sortable: false },
        { text: this.$t('product.discount'), value: "discount", sortable: true },
        { text: this.$t("product.action"), value: "actions", sortable: false },
      ]
    }
  },
  watch: {
    type: {
      handler() {
        this.search = '';
        this.page = 1;
        this.limit = 10;
      },
      deep: true
    },
    params: {
      handler(newVal, oldVal) {
        if(JSON.stringify(newVal)!==JSON.stringify(oldVal) || !Object.keys(this.$route.query).length)
          this.paginate(false);
      },
      deep: true
    },
    '$route.query': {
      handler(newVal,oldVal) {
        if(JSON.stringify(newVal)!==JSON.stringify(oldVal) || !Object.keys(this.$route.query).length)
          this.page = Number(this.$route.query.page) || 1
          
          this.sortByValue = this.$route.query.order_by || null

          this.sortDescValue = this.$route.query.sortDec ? true : null

          this.search = this.$route.query.search || ""
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {
    this.listSkeleton = true;
    this.$store.commit("priceList/SET_PRICE_LIST_QUERY", this.$route.query);
    if(Object.keys(this.$route.query).length){
      this.paginate(false);
    } else {
      this.pagination.page = 1
      this.pagination.itemsPerPage = 10
      this.pagination.sortBy = [false]
      this.pagination.sortDesc = [false]
    }
  },
  methods: {
    async paginate() {
      const {
        sortBy = this.pagination.sortBy,
        sortDesc = this.pagination.sortDesc,
        page = this.pagination.page,
        itemsPerPage = this.pagination.itemsPerPage
      } = this.params;
      await this.$store.dispatch('priceList/GetPriceListData', {page: page, limit: itemsPerPage, where_like: this.search ? sortBy[0] ? sortBy[0]+'|'+this.search : sortBy[1] ? sortBy[1]+'|'+this.search : sortBy[2] ? sortBy[2]+'|'+this.search : 'discount|'+ this.search : 'discount|', order_by: 'discount,id|'+(sortDesc?.[0] ? 'desc' : 'asc') +',desc'}).then(() => this.listSkeleton = false);
    },
    buildUrl(val) {
      if (!this.type) {
        let obj = {};
        if (typeof val === 'string' || typeof val === 'object') {
          // this.page = 1;
          // obj.page = 1;
          // this.params.page = 1;
        }
        obj.search = this.search;
        obj.page = this.page;
        obj.order_by = this.sortByValue;
        obj.sortDec = this.sortDescValue;
        obj = Object.entries(obj).reduce((acc, [key, val]) => {
          if (key === "page" && val === 1) return acc;
          else if (!val) return acc;
          return { ...acc, [key]: val };
        }, {});
        this.$router.push({
          name: "PriceListTable",
          query: {
            ...obj,
          },
        }).catch(()=>{});
        this.$store.commit("priceList/SET_PRICE_LIST_QUERY", obj);
      }
    },
    redirectOnEdit(_, {item}) {
      this.editItemData = Object.assign({}, item)
      this.priceListFormDialog = true
    },
    async deleteItem(item) {
      await this.$store.dispatch('priceList/DeletePriceListData', item.id);
      await this.$store.dispatch('priceList/GetPriceListData', {page: 1, limit: 10});
    }
  }
}
</script>

<style scoped>
::v-deep .v-pagination__item {
  font-size: 12px;
}
</style>