<template>
  <v-dialog v-model="dialog" width="500" @click:outside="$emit('update:dialog', false);">
    <v-card>
      <v-card-title class="primary--text px-4 py-1 text-body-1">
        {{ $t('auxiliaryTable.confirm') }}
        <v-spacer />
        <v-btn
          icon
          @click="$emit('update:dialog', false);"
        >
          <v-icon color="primary darken-1">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="text-body-1 mt-4">
        {{ $t('auxiliaryTable.confirmMessage') }}
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          class="text-capitalize"
          outlined
          small
          @click="$emit('update:dialog', false);"
        >
          {{ $t('auxiliaryTable.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          class="text-capitalize"
          small
          @click="deleteData"
        >
          {{ $t('auxiliaryTable.confirm') }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ConfirmationDialo",
  props: ["dialog", "delete_item"],
  watch: {
    dialog() {
      if(!this.dialog) {
        this.$emit('update:delete_item', '');
      }
    }
  },
  methods: {
    deleteData(){
      this.$emit('deleteItem', this.delete_item);
      this.$emit('update:dialog', false);
    }
  }
}
</script>