<template>
  <div>
    <v-dialog
      content-class="custom-dialog-content"
      v-model="dialog"
      @click:outside="closeDialog"
      max-width="40%"
    >
    <v-card class="mx-auto" outlined>
      <v-card-text class="pa-0 text-end">
        <v-icon
          class="mt-2 mx-2 primary--text"
          @click="closeDialog"
          >mdi-close</v-icon
        >
      </v-card-text>
      <v-card-text class="text-h5 px-4">
        <v-form ref="form" v-model="valid" lazy-validation :disabled="accessRight.length < 2 && accessRight.includes('show')">
         <Alert :alert.sync="alert" :message="message" />
          <v-row>
            <v-col cols="12">
              <v-stepper class="pb-0" v-model="e6" vertical non-linear flat>
                <v-stepper-step
                  class="py-3 px-2 text-h6"
                  editable
                  step="1"
                >
                  <v-row dense>
                    <v-col cols="6" dense>
                      <h6 class="primary--text mt-2 font-weight-regular">
                        {{ $t("route.priceList") }}
                      </h6>
                    </v-col>
                    <v-col cols="6" class="text-end" dense>
                      <v-btn
                        color="primary"
                        small
                        :disabled="
                          !valid ||
                          (accessRight.length < 2 &&
                            accessRight.includes('show'))
                        "
                        @click="savePriceData"
                      >
                        {{ $t("contact.save") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-stepper-step>
                <v-stepper-content
                  step="1"
                  class="ml-5 mb-auto pb-0 mr-3 py-2 px-2"
                >
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        class="asterisk"
                        hide-details="auto"
                        v-model="itemgroupid"
                        :items="productGroupValue"
                        item-text="description"
                        item-value="id"
                        clearable
                        flat
                        return-object
                        :label="$t('product.field.group')"
                        :rules="itemGroupValid"
                        @change="onItemGroupChange"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-autocomplete
                        class="asterisk"
                        hide-details="auto"
                        v-model="pricegroupid"
                        :items="priceListGroupValue"
                        item-text="description"
                        item-value="id"
                        clearable
                        flat
                        return-object
                        :label="$t('product.priceListGroup')"
                        :rules="priceListGroupValid"
                        @change="onPriceGroupChange"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        class="mb-3 asterisk"
                        :label="$t('product.discount')"
                        required
                        v-model="discount"
                        :rules="discountValid"
                      />
                    </v-col>
                  </v-row>
                </v-stepper-content>
              </v-stepper>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Alert from "@/components/Alert";
import { getAuxiliaryZtableValueByName } from "@/utils";

export default {
  name: "PriceListForm",
  props: ["dialog", "editItemData"],
  components: {
    Alert,
  },
  data() {
    return {
      valid: false,
      e6: 1,
      discount: null,
      customerPriceData: {},
      alert: false,
      message: "",
      pricegroupid: null,
      productGroupValue: [],
      itemgroupid: null,
      priceListGroupValue: [],
      id: null,
      isExist: null,
      checkIsExist: false,
    };
  },
  mounted() {
    getAuxiliaryZtableValueByName(3).then((tableProductValue) => {
      if (Object.keys(tableProductValue).length > 0) {
        this.productGroupValue = tableProductValue.ztables.sort((a, b) =>
          a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
            ? 1
            : -1
        );
        this.productGroupValue = [...this.productGroupValue]
      }
    });
    getAuxiliaryZtableValueByName(18).then((tableProductValue) => {
      if (Object.keys(tableProductValue).length > 0) {
        this.priceListGroupValue = tableProductValue.ztables.sort((a, b) =>
          a.description.toLocaleLowerCase() > b.description.toLocaleLowerCase()
            ? 1
            : -1
        );
        this.priceListGroupValue = [...this.priceListGroupValue]
      }
    });
  },
  watch: {
    editItemData() {
      if (this.editItemData) {
        this.discount = this.editItemData?.discount,
        this.itemgroupid = this.editItemData?.itemgroupid,
        this.pricegroupid = this.editItemData?.pricegroupid,
        this.id = this.editItemData?.id
      }
    }
  },
  computed: {
    ...mapGetters({
      accessRight: "accessRight",
    }),
    itemGroupValid() {
      return [
        (v) => !!v || this.$t("formRules.itemGroupRequired"),
      ];  
    },
    priceListGroupValid() {
      return [
        (v) => !!v || this.$t("formRules.priceListGroupRequired"),
      ];  
    },
    discountValid() {
      return [
        (v) => !!v || this.$t("formRules.discountRequired"),
        (v) => !v || /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/.test(v) || this.$t("formRules.spaceValidValue"),
        (v) => !v || (v && String(v).length <= 6) || this.$t("formRules.lengthValidMessage", { fieldName: this.$t("product.discount"), type: this.$t("common.less"), size: 6 }),
      ];
    },
  },
  methods: {
    lessThanValidation(fieldValue, typeValue, sizeValue, v) {
      return (
        !v ||
        (v && v.length <= sizeValue) ||
        this.$t("formRules.lengthValidMessage", {
          fieldName: fieldValue,
          type: typeValue,
          size: sizeValue,
        })
      );
    },
    onItemGroupChange(itemGroup) {
      this.checkIsExist = true;
      if (itemGroup && typeof itemGroup === "object" && Object.keys(itemGroup).length > 0) {
        this.itemgroupid = { id: itemGroup.id, description: itemGroup?.description };
      } else {
        this.itemgroupid = null;
      }
    },
    onPriceGroupChange(priceGroup) {
      this.checkIsExist = true;
      if (priceGroup && typeof priceGroup === "object" && Object.keys(priceGroup).length > 0) {
        this.pricegroupid = { id: priceGroup.id, description: priceGroup?.description };
      } else {
        this.pricegroupid = null;
      }
    },
    async closeDialog() {
      this.$emit('update:dialog', false);
      this.$refs.form.reset();
      this.id = null
    },
    async savePriceData() {
      if (this.$refs.form.validate()) {
        const payload = {
          discount: Number(this.discount),
          priceGroupId: this.pricegroupid?.id,
          ztable_id: this.itemgroupid?.id,
          id: this.id
        }
        if (!payload.id || (payload.id && this.checkIsExist)) {
          await this.$store.dispatch('priceList/DataIsExist', {
            priceGroupId: this.pricegroupid?.id,
            ztable_id: this.itemgroupid?.id,
          }).then((res) => {
            this.isExist = res
          }) 
        }
        if (this.isExist?.type === 'success') {
          this.valid = false;
          this.message = this.isExist?.message;
          this.alert = true;
          this.$emit("update:dialog", true);
        } else {
          if (payload.id) {
            await this.$store.dispatch('priceList/EditPriceListData', payload)
            this.checkIsExist = false;
          } else {
            delete payload.id
            await this.$store.dispatch('priceList/SetPriceListData', payload)
          }
          this.$emit("update:dialog", false);
          this.$refs.form.reset();
          this.id = null;
          await this.$store.dispatch('priceList/GetPriceListData', {page: 1, limit: 10});
        }
      } else {
        this.valid = false;    
        this.message = "formRules.formAlert";
        this.alert = true;
      }
    },
  },
};
</script>